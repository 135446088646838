import React, { useState } from 'react';
import ItemInserter from "../items/ItemInserter";
import { useTranslation } from 'react-i18next';

const ItemAddButton = () => {
  const [isCreateItemOpened, setCreateItemOpened] = useState(false);
  const { t } = useTranslation();

  return (
    <div className='col p-3'>
        <button
            onClick={(e)=>{setCreateItemOpened(true);}}
            className="btn btn-primary w-100"
        >
        {t('item.add.new')}
        </button>
        <ItemInserter isOpen={isCreateItemOpened} handleClose={()=> {setCreateItemOpened(false);}}/>
    </div>
    
  );
};

export default ItemAddButton;
