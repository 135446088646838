import React, { useEffect, useState } from "react";

const AdminButtons = ({userId, userActivated, userActivationControl}) => {

    const [activeStatus, setActive] = useState(false);
    const [blockText, setBlockText] = useState("");

    useEffect(()=>{
        if(userActivated) {
            setBlockText("Deactivate");
            setActive(true);
        } else {
            setBlockText("Activate");
            setActive(false);
        }
    },[userActivated]);

    console.log(blockText);

    function handleBlockButton() {
        userActivationControl(userId);
    }

    return (
        <div className={activeStatus ? "btn btn-warning" : "btn btn-success"} onClick={handleBlockButton}> {blockText} </div>
    );
}

export default AdminButtons;