import React, { useEffect, useState } from "react";
import MyItemsAxios from "../backendTools/MyItemsAxios";
import UserButtons from "./UserButtons";
import ModalConfirm from "../components/ModalConfirm";
import {Row, Col, Card, Button } from 'react-bootstrap';
import RequestTransitButton from "./RequestTransitButton";

import orgUsersList from "../TestData/orgUsersList";

const MemberList = () => {

    const [usersList, setUserList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalOnConfirm, setModalOnConfirm] = useState(null);
    const [modalOnDecline, setModalOnDecline] = useState(null);

    useEffect(() => {
        async function getUsers() {
            try {
                //const response = await MyItemsAxios.get('/organization/get-all-users');
                
                setUserList(orgUsersList); //response.data
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }
        
        getUsers();

    }, []);

    function deactivateUser(userId) {
        MyItemsAxios.put('/organization/' + userId + '/deactivate');
    }

    function activateUser(userId) {
        MyItemsAxios.put('/organization/' + userId + '/activate');
    }

    function promoteUser(userId, role) {
        MyItemsAxios.put('/organization/' + userId + '/new-role/' + role);
    }

    function handleBlockButton(userId) {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if (userIndex != -1) {
            const actionText = usersList[userIndex].activated ? "deactivate" : "activate";
            setModalMessage(`Are you sure you want to ${actionText} this user?`);
            setModalOnConfirm(() => () => {
                if (usersList[userIndex].activated) {
                    deactivateUser(userId);
                } else {
                    activateUser(userId);
                }
                setShowModal(false);
                window.location.reload();
            });
            setModalOnDecline(() => () => setShowModal(false));
            setShowModal(true);
        }
    }

    function handlePromote(userId, newRole) {
        const userIndex = usersList.findIndex(user => user.id === userId);
        if (userIndex != -1) {
            setModalMessage(`Are you sure you want to promote this user to ${newRole}?`);
            setModalOnConfirm(() => () => {
                promoteUser(userId, newRole);
                setShowModal(false);
                window.location.reload();
            });
            setModalOnDecline(() => () => setShowModal(false));
            setShowModal(true);
        }
    }

    function getRoleClass(user) {
        if(user.activated) {
            switch (user.role) {
                case 'Manager':
                    return 'table-warning';
                case 'Admin':
                    return 'table-danger';
                default:
                    return 'table-info';
            }
        } else {
            return 'table-success';
        }
        
    }

return (
    <div className="container mt-5 p-3 bg-light" style={{'maxWidth':'900px'}}>
        <Row className="my-3">
            <Col xs={12} md={6}><Card>
                    <Button variant="primary" href="/organization/invite">Invite new member</Button> 
                </Card></Col>
            <Col xs={12} md={6}><Card><RequestTransitButton/> </Card></Col>
        </Row>
        <table className="table table-stripped">
            <thead>
                <tr>
                    <td> Username </td>
                    <td> E-mail </td>
                    <td> Roles </td>
                    <td> Status </td>
                    <td> Actions </td>
                </tr>
            </thead>
            <tbody>
            {usersList && usersList.map((user, index) => (
                <tr key={index} className={getRoleClass(user)}>
                    <td> {user.username} </td>
                    <td> {user.email} </td>
                    <td> {user.role} </td>
                    <td> {user.activated ? "Activated" : "Invited"} </td>
                    <td> <UserButtons   userId={user.id} 
                                        userActivated={user.activated} 
                                        userActivationControl={handleBlockButton}
                                        changeRole={handlePromote}/> 
                    </td>
                </tr>
            )) }
            </tbody>
        </table>
        <ModalConfirm
            message={modalMessage}
            isOpen={showModal}
            onConfirm={modalOnConfirm}
            onDecline={modalOnDecline}
        />
    </div>
);
}

export default MemberList;