import React, { useState } from "react";
import backendHost from "../backendTools/backendHost";
import MyItemsAxios from "../backendTools/MyItemsAxios";

const InviteMember = () => {

    const [isMailValid, setMailValid] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);

    async function handleInvite(event) {
        event.preventDefault();
        const mail = document.getElementById("email").value;
        const userMail = mail.trim();
        const inviteForm = {
            email               : userMail,
        };
        await MyItemsAxios.post(backendHost + "/organization/invite-member", inviteForm)
            .catch(function (error) {
                if (error.response) {
                    setAlertMessage(error.response.data);
                }
            }).then(setAlertMessage("Invite been sent"));
    }

    function isEmailValid(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateEmail(event) {
        const newMailValue = event.currentTarget.value.trim();
        if(newMailValue !== '') {
            var isMailValid = isEmailValid(newMailValue);
            setMailValid(isMailValid);
        } else {
            setMailValid(true);
        }
    }


    return (
        <div className="container py-5" style={{'maxWidth': '700px'}}>
            <div className="row p-3 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-4 bg-light rounded-4">
                    {alertMessage && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        {alertMessage}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" 
                            onClick={()=>{setAlertMessage(null);}} aria-label="Close">
                        </button>
                    </div>}
                    <form className="p-2" onSubmit={handleInvite}> 
                        <div className="p-2">
                            <input  type="text" 
                                    id="email"
                                    className="form-control" 
                                    placeholder="E-mail"
                                    onBlur={validateEmail}
                                    required/>
                            {!isMailValid && <p style={{ color: 'red' }}>Invalid Email</p>}
                        </div>
                        <div className="p-2">
                            <button type="submit"  className="form-control btn btn-outline-success"> Send Invite </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default InviteMember;
