// DummyData.js
export const dummyRequests = [
    {
      id: '1',
      requestedItemId: 90,
      authorName: 'John Doe',
      receiverName: 'Jane Smith',
      managerName: 'Alice Johnson',
      dateOfCreation: '2023-04-01T10:00:00Z',
      requestDescription: 'Fix the broken printer in the office.',
      requestStatus: 'pending',
      dateOfSolving: null
    },
    {
      id: '2',
      requestedItemId: 106,
      authorName: 'Jane Smith',
      receiverName: 'John Doe',
      managerName: 'Bob Brown',
      dateOfCreation: '2023-04-02T14:30:00Z',
      requestDescription: 'Update the software on all servers.',
      requestStatus: 'approved',
      dateOfSolving: '2023-04-05T16:00:00Z'
    },
    {
      id: '3',
      requestedItemId: 90,
      authorName: 'Alice Johnson',
      receiverName: 'Bob Brown',
      managerName: 'Charlie Davis',
      dateOfCreation: '2023-04-03T18:00:00Z',
      requestDescription: 'Add new user permissions for the sales department.',
      requestStatus: 'declined',
      dateOfSolving: null
    },
    {
      id: '4',
      requestedItemId: 106,
      authorName: 'Bob Brown',
      receiverName: 'Alice Johnson',
      managerName: 'David Evans',
      dateOfCreation: '2023-04-04T12:00:00Z',
      requestDescription: 'Schedule a meeting with the marketing team.',
      requestStatus: 'pending',
      dateOfSolving: null
    },
    {
      id: '5',
      requestedItemId: 106,
      authorName: 'Charlie Davis',
      receiverName: 'David Evans',
      managerName: 'Eve Foster',
      dateOfCreation: '2023-04-05T16:30:00Z',
      requestDescription: 'Fix the network issues.',
      requestStatus: 'approved',
      dateOfSolving: '2023-04-08T19:00:00Z'
    },
    {
      id: '6',
      requestedItemId: 106,
      authorName: 'David Evans',
      receiverName: 'Eve Foster',
      managerName: 'Frank Gibson',
      dateOfCreation: '2023-04-06T10:00:00Z',
      requestDescription: 'Update the company website.',
      requestStatus: 'declined',
      dateOfSolving: null
    },
    {
      id: '7',
      requestedItemId: 115,
      authorName: 'Eve Foster',
      receiverName: 'Frank Gibson',
      managerName: 'Grace Harris',
      dateOfCreation: '2023-04-07T14:00:00Z',
      requestDescription: 'Add new features to the CRM system.',
      requestStatus: 'pending',
      dateOfSolving: null
    }
  ];