import React, { useState, useEffect } from 'react';
import MyItemsAxios from '../backendTools/MyItemsAxios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ItemName from './itemTools/ItemName';
import ItemImage from './itemTools/ItemImage';
import ItemServicability from './itemTools/ItemServicability';
import ItemLocation from './itemTools/ItemLocation';
import ItemCategories from './itemTools/ItemCategories';
import ItemDeliveryStatus from './itemTools/ItemDeliveryStatus';
import ItemDescription from './itemTools/ItemDescription';
import ItemNoteManager from './itemTools/ItemNoteManager';
import ModalConfirm from '../components/ModalConfirm';
import ModalCategories from '../components/ModalCategories';
import {transformNullsToZeros} from '../categoryTools/CategoryTree';
import ItemButtons from './itemTools/ItemButtons';
import { getLocations } from '../LocationsList';
import BackButton from '../components/BackButton';

const requestItemUrl = "/item";
const requestCategoriesURl = "/category";

function ItemReceiver() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [isDeleteRequested, setDeleteRequest] = useState(false);
  const [isEditingItem, setEditingItem] = useState(false);
  const navigate = useNavigate();
  const editItemUrl = "/item/"+id+"/edit";
  const [categoriesList, setCategoriesList] = useState(null);
  const [categoryEditActive, setCategoryEdit] = useState(false);
  const [locations, setLocations] = useState(null);
  const { t } = useTranslation();

  async function getItem(id) {
    try {
      const response = await MyItemsAxios.get(requestItemUrl + '/' + id);
      setItem(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  async function sendItem() {
    try {
      await MyItemsAxios.put(requestItemUrl, item);
    } catch (err) {
      console.error('Error updating item:', err);
    }
  }

  useEffect(() => {

    // Request item data after page is loaded
    async function getCategoriesList() {
      try {
        const respondedCategories = await MyItemsAxios.get(requestCategoriesURl);
        return respondedCategories.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    getCategoriesList()
      .then(transformNullsToZeros)
      .then(setCategoriesList)
      .then(getItem(id));
    getLocations()
    .then(setLocations);
  }, [id]); // Empty array means this effect runs only once after the component mounts

  function makeCategorySearch(categoryId) {
    navigate("/page/1?category=" + categoryId);
  }

  function onItemNameChange(newName) {
    setItem(prevState => ({
      ...prevState, name: newName
    }));
  }

  function onItemServisabilityChange(newCondition) {
    setItem(prevState => ({
      ...prevState, stateOfServiceability: newCondition
    }));
  }

  function onItemDescriptionChange(newDescription) {
    setItem(prevState => ({
      ...prevState, description: newDescription
    }));
  }

  function onItemLocationChange(newLocation) {
    setItem(prevState => ({
      ...prevState, location: newLocation
    }));
  }

  async function handleAddCategory(categoryIdArray) {
    try {
      const categoryUpdateURl = requestItemUrl + "/" + id + "?category=" + categoryIdArray;
      await MyItemsAxios.put(categoryUpdateURl, null);
      //console.log(response.data); 
      setItem(prevState => ({
        ...prevState, categories: categoryIdArray
      }));       
    } catch {
      console.error("Error adding category");
    }
  }

  function updateItem() {
    setEditingItem(false);
    sendItem();
  }

  function restoreItem() {
    setEditingItem(false);
    getItem(id);
  }

  function deleteItem() {
    //setDeleteRequest(false);
    MyItemsAxios.delete(requestItemUrl + '/' + id)
      .then(()=> {
        navigate("/page/1");
    }); 
  }

  return (
      <div className="container  mt-5 py-5 px-4" style={{'maxWidth': '700px'}}>
        {item ? (
          <>
          <div className='row border rounded-4 p-1 bg-white mb-3 justify-content-between'>
            <div className="p-0 px-2 col-auto"> 
              {(categoriesList != null && item.categories.length !== 0) && (
                <ItemCategories categoriesOfItem={item.categories} 
                                categoryList={categoriesList}
                                onCategoryClick={makeCategorySearch}/>
            )}
            </div>
            <div className='col-auto'> 
                <span className='btn btn-outline-success my-1' onClick={()=>{setCategoryEdit(true);}}> 
                  <i  className="bi bi-diagram-3"/>
                </span>
            </div>
          </div>
          <div className="row border rounded-4 bg-white p-2 pt-2">
            <div className='col-12 p-3 m-0 d-block d-sm-none'> 
              <div className='row justify-content-between'>
                <div className='col-auto'>
                  <ItemName name={item.name} isEditing={isEditingItem} updateName={onItemNameChange}/>
                  <ItemServicability  serviceable={item.stateOfServiceability} isEditing={isEditingItem}
                                      onChange={onItemServisabilityChange}/>
                </div> 
                <ItemButtons  isEditing={isEditingItem} 
                              updateItem={updateItem}
                              restoreItem={restoreItem}
                              setDeleteRequest={setDeleteRequest}
                              setEditingItem={setEditingItem}/> 
              </div>
            </div>
            <div className="col-12 col-sm-4 col-lg-3 mt-0  mt-sm-2 p-0">
              <div className="row ms-0 me-1 ms-sm-1 me-sm-0 text-center">
                <div className="col-12 p-0">
                  <ItemImage photo={item.photo} itemId={item.id}/>
                </div>
              </div>
            </div>
            <div className='col-1 d-none d-lg-block'> </div>
            <div className="col-12 col-sm-8 align-top h-100">
              <div className="col-auto py-2 d-none d-sm-block">
                <div className='row justify-content-between'>
                  <div className='col-auto'>
                    <ItemName name={item.name} isEditing={isEditingItem} updateName={onItemNameChange}/>
                    <ItemServicability  serviceable={item.stateOfServiceability} isEditing={isEditingItem}
                                        onChange={onItemServisabilityChange}/>
                  </div> 
                  <ItemButtons  isEditing={isEditingItem} 
                                updateItem={updateItem}
                                restoreItem={restoreItem}
                                setDeleteRequest={setDeleteRequest}
                                setEditingItem={setEditingItem} /> 
                </div>
              </div>
              <div className='p-3 p-sm-1'>
              
              <ItemLocation location={item.location} locationsList={locations}
                            isEditing={isEditingItem} onChange={onItemLocationChange}/>          
              <ItemDeliveryStatus status={item.delivered}/>
              <ItemDescription  description={item.description} isEditing={isEditingItem} 
                                onChange={onItemDescriptionChange}/>
              </div>
              <ModalConfirm message={t('item.delete')}
                            isOpen={isDeleteRequested}
                            onConfirm={deleteItem}
                            onDecline={()=>{setDeleteRequest(false);}}/>
            </div>
          </div>
          <div className='p-2'>
            <BackButton />
          </div>
          <ItemNoteManager itemId={item.id} noteList={item.notes}/>
          <ModalCategories  isOpen={categoryEditActive}
                            onClose={()=>{setCategoryEdit(false);}}
                            categoryList={categoriesList} 
                            categoriesOfItem={item.categories}
                            handleAddCategory={handleAddCategory}/>
          </>
        ) : (
          <div className='row p-5 justify-content-center'>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">{t('label.loading')}</span>
            </div>
          </div>
        )}
      </div>
  );
}

export default ItemReceiver;
