import React, {useState, useEffect, useRef} from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Modal } from "react-bootstrap";
import ModalConfirm from "../../components/ModalConfirm";
import MyItemsAxios from "../../backendTools/MyItemsAxios";
import heic2any from "heic2any";
import { useTranslation } from 'react-i18next';

const s3bucketUrl = 'https://my-items-net.s3.amazonaws.com/';

function ItemImage ({photo, itemId}) {

    const [baseImage, setBaseImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isPreviewModeOn, setPreviewModeOn] = useState(false);
    const [isModalOpened, setModalOpened] = useState(false);

    const [isConverting, setConverting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isImageEmpty, setImageEmpty] = useState(false);

    const [isFullScreen, setFullScreen] = useState(false);

    const { t } = useTranslation();

    const buttonsEnabled = (itemId != null);
    
    const photoRequestUrl = "/photo";

    const inputFileRef = useRef( null );
    
    useEffect(() => {

        function isServerImageEmpty () {
            return (photo == null || (photo.photoBase64 == null && photo.fileName == null));
        }

        function getBase64Url() {
            //set base64 data string with foto as constant
            const base64String = photo.photoBase64;
            // Create a data URL
            return (`data:image/;base64,${base64String}`); // Adjust the mime type if necessary
        }

        function showImage() {
            var dataUrl;
            if(isServerImageEmpty()) {
                dataUrl = '/NoImage.png';
                setImageEmpty(true);
            } else if(photo.fileName != null) {
                dataUrl = s3bucketUrl + photo.fileName;
            } else {
                dataUrl = getBase64Url();
            }
            setBaseImage(dataUrl);
            setDisplayImage(dataUrl);
        };

        showImage();
    }, [photo]);


    function showFullScreen() {
        console.log("should open fullscreen")
        setFullScreen(true);
    }

    function hideFullScreen() {
        setFullScreen(false);
    }
    async function uploadFile(formData) {
        try {
          await MyItemsAxios.post(photoRequestUrl, formData);
          //console.log('Success update');
          setPreviewModeOn(false);
          setImageEmpty(false);
        } catch (error) {
          console.log('Update error');
        }
      };
    
    //prepare file to be uploaded
    async function uploadPhoto() {    
        if (selectedFile) {
            setLoading(true);
            const formData = new FormData();
            formData.append('itemId', itemId);
            formData.append('photo', selectedFile);
            await uploadFile(formData);
            setLoading(false);
        }
    };
    
    function deletePhoto () {
        MyItemsAxios.delete(photoRequestUrl + '/' + itemId);
        setImageEmpty(true);
    }

    function handleDeleteButton() {
        setModalOpened(true);
    };

    async function handleDelete () {
        deletePhoto();
        setDisplayImage('/NoImage.png');
        setModalOpened(false);
    };

    function handleDecline() {
        setModalOpened(false);
    }
    
    function prepareUpload() {
        inputFileRef.current.click();
    };

    function removePreview() {
        setPreviewModeOn(false);
        //remove loaded photo
        setDisplayImage(baseImage);
        setSelectedFile(null);
        inputFileRef.current.value = null;
    };

    function convertHEICtoJPEG(file) {
        return heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.8,
        });
    };

    function handleImageClick() {
        if(buttonsEnabled) {
            if(isImageEmpty) {
                prepareUpload();
            } else {
                showFullScreen();
            }
        }
    }

    async function handleFileChange (event) {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            console.log("filetype: ", selectedFile.type);
            setPreviewModeOn(true);
            if (selectedFile.name.includes('.HEIC') || selectedFile.name.includes('.heic')) {
                setConverting(true);
                try {
                    const convertedBlob = await convertHEICtoJPEG(selectedFile);
                    setDisplayImage(URL.createObjectURL(convertedBlob));
                    setSelectedFile(convertedBlob);
                    setConverting(false);
                } catch (error) {
                    console.error('Conversion error:', error);
                }
            } else {
                console.log("Img is not HEIC");
                setDisplayImage(URL.createObjectURL(selectedFile));
                setSelectedFile(selectedFile);
            }
        } else {
            setPreviewModeOn(false);
        }
    };

    return (
        <>
            {isConverting ? (
                <div className='row p-5 justify-content-center'>
                    <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">{t('label.loading')}</span>
                    </div>
                </div>
            ) : (
                <img className="img-fluid p-2" alt='' src={displayImage} onClick={handleImageClick} style={{ cursor: 'pointer' }}/>
            )}
            { buttonsEnabled && (
                <div className="col-12 mt-2 mt-sm-3 mx-0 mb-3 mb-sm-4 p-0 row justify-content-evenly">
                    <button className="btn btn-outline-success col-5" hidden={isPreviewModeOn} onClick={prepareUpload}> <i className="bi bi-cloud-upload"></i></button>
                    <button className="btn btn-outline-success col-5" hidden={!isPreviewModeOn} onClick={uploadPhoto}> 
                        {isLoading ? (
                            <div className="spinner-border spinner-border-sm text-warning" role="status">
                                <span className="visually-hidden">{t('label.loading')}</span>
                            </div>
                        ) : (<i className="bi bi-check-lg"></i>)
                        } 
                    </button>
                    <button className="btn btn-outline-danger  col-5" hidden={!isPreviewModeOn} onClick={removePreview}> <i className="bi bi-x-lg"></i> </button>
                    { !isImageEmpty && <button className="btn btn-outline-danger  col-5" hidden={isPreviewModeOn} onClick={handleDeleteButton}> <i className="bi bi-trash"></i> </button>}
                    <form>
                        <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={handleFileChange}
                            ref={inputFileRef} />
                    </form>
                    <ModalConfirm message={t('photo.delete')}
                        isOpen={isModalOpened}
                        onConfirm={handleDelete}
                        onDecline={handleDecline} />
                </div>)
            }
            <Modal  show={isFullScreen} 
                    onHide={hideFullScreen}
                    centered size="lg"
                    contentClassName="rounded-4">
                <Modal.Body>
                    <img src={displayImage} className="w-100"></img>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ItemImage;