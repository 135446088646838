import React, { useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { useCookies } from 'react-cookie';

import NavBar from './NavBar';
//import Footer from './Footer';


import ItemReceiver from './items/ItemReceiver';
import CategoriesList from './CategoriesList';
import LoginPage from './user/LoginPage';
import RegistrationEntry from './user/RegistrationEntry';
import CheckMail from './user/CheckMail';
import Landing from './user/Landing';
import RegistrationCheck from './user/RegistrationCheck';
import UserList from './adminPages/UserList';
import RestorePassword from './user/RestorePassword';
import Configuration from './Configuration';
import MainPage from './MainPage';
import ListItems from './items/ListItems';
import HelpPage from './user/HelpPage';
import InviteUser from './adminPages/InviteUser';
import RegistrationBossComplete from './organization/RegistrationBossComplete';
import MemberList from './organization/MemberList';
import InviteMember from './organization/InviteMember';
import RegistrationUserComplete from './organization/RegistrationUserComplete';
import ImportExport from './importExport/ImportExport';
import RequestsList from './organization/RequestsList';

function App() {

    const [isUserLogged, setUserLogged] = useState(false);
    const [cookies, setCookie] = useCookies(['user', 'email']);

    useEffect( () => {
        if(localStorage.getItem('refreshToken')) {
            setUserLogged(true);
        }
    }, []);

    function onLogin(tokens) {
        setUserLogged(true);
        localStorage.setItem('refreshToken', tokens.refreshToken);
        localStorage.setItem('accessToken' , tokens.accessToken);        
    }

    function onLogout() {
        setUserLogged(false);
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
    }

    function onRegister(user, email) {
        const expires = new Date();
        expires.setMonth(expires.getMonth() + 1); // Срок действия куки - 1 месяц
        setCookie('user',  user,  { path: '/', expires });
        setCookie('email', email, { path: '/', expires });
    }

    return (
        <Router>
            {<NavBar    isLogged={isUserLogged} onLogout={onLogout} />}
            <Routes>
                <Route path="/"                             element={isUserLogged ? <MainPage /> : <Landing     />}/>
                <Route path="/login"                        element={<LoginPage onLogin={onLogin}               />} />
                <Route path='/registration'                 element={<RegistrationEntry onRegister={onRegister} />} />
                <Route path='/restore-password'             element={<RestorePassword onLogin={onLogin}         />} />
                <Route path="/page/:pageNumber"             element={<ListItems                                 />} />
                <Route path="/item/:id"                     element={<ItemReceiver                              />} />
                <Route path="/categories"                   element={<CategoriesList                            />} />
                <Route path="/configuration"                element={<Configuration                             />} />
                <Route path="/checkMail"                    element={<CheckMail username={cookies.user} email={cookies.email}/>} />
                <Route path="/confirmRegistration"          element={<RegistrationCheck     onConfirm={onLogin} />} />
                <Route path="/help"                         element={<HelpPage                                  />} />
                <Route path="/organization-registration"    element={<RegistrationBossComplete  onConfirm={onLogin} />} />
                <Route path="/organization/user/registration"    element={<RegistrationUserComplete  onConfirm={onLogin} />} />
                <Route path="/organization/users"           element={<MemberList                                />} />
                <Route path="/organization/invite"          element={<InviteMember                              />} />
                <Route path="/organization/import-export"   element={<ImportExport                              />} />
                <Route path="/organization/requests"        element={<RequestsList                              />} />
                <Route path="/admin"                        element={<UserList                                  />} />
                <Route path="/admin/invite"                 element={<InviteUser                                />} />
            </Routes>
            {/* <Footer /> */}
        </Router>
    );
}

export default App;
