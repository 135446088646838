// Request.js
import React from 'react';

const RenderRequest = ({ request, onAction }) => {

  async function handleActionClick (action) {
    if(onAction) {
      onAction(request.id, action);
    }
  };

  return (
    <div className="card mb-3">
      <div className={`card-body ${request.requestStatus == "approved" ? 'bg-success-subtle' : ''}
                                 ${request.requestStatus == "declined" ? 'bg-danger-subtle' : ''}`}>
        <div className='row'>
          <div className='col-6'>
            {request.requestStatus === 'pending' ? (
              <>
                <p className="card-text">Requested Item ID: {request.requestedItemId}</p>
                <p className="card-text">Date of Creation: {new Date(request.dateOfCreation).toLocaleString()}</p>
                <p className="card-text">Item Receiver Name: {request.receiverName}</p>
                <p className="card-text">Manager Name: {request.managerName}</p>
              </>
            ) : (
              <>
                <p className="card-text">Requested Item ID: {request.requestedItemId}</p>
                <p className="card-text">Date of Creation: {new Date(request.dateOfCreation).toLocaleString()}</p>
                <p className="card-text">Date of Solving: {new Date(request.dateOfSolving).toLocaleString()}</p>
                <p className="card-text">Manager Name: {request.managerName}</p>
                <p className="card-text">Receiver Name: {request.receiverName}</p>
                <p className="card-text">Status: {request.requestStatus}</p>
              </>
            )}
          </div>
          <div className='col-6'> Item image</div>
        </div>
        
        {request.requestStatus === 'pending' && (
          <>
            <button onClick={() => handleActionClick('approve')} className="btn btn-success mr-2">Approve</button>
            <button onClick={() => handleActionClick('decline')} className="btn btn-danger">Decline</button>
          </>
        )}
      </div>
    </div>
  );
};

export default RenderRequest;