// RequestsList.js
import React, { useEffect, useState } from 'react';
import { getRequests, updateRequest } from '../backendTools/requestService';
import RenderRequest from '../components/RenderRequest';
import ModalConfirm from '../components/ModalConfirm';

const RequestsList = () => {
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmMessage, setModalConfirmMessage] = useState('');
  const [modalConfirmData, setModalConfirmData] = useState(null);

  const [pendingRequests, setPendingRequests] = useState([]);
  const [solvedRequests, setSolvedRequests] = useState({ pending: [], solved: [] });
  const [displayRequests, setDisplayRequest] = useState([]);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const data = await getRequests();
        sortRequests(data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      }
    };

    fetchRequests();
    setDisplayRequest(pendingRequests);
  }, []);

  const sortRequests = (requests) => {
    const pending = requests.filter(req => req.requestStatus === 'pending');
    const solved = requests.filter(req => req.requestStatus !== 'pending');
    setPendingRequests(pending)
    setSolvedRequests(solved);
    setDisplayRequest(pending);
  };

  async function handleAction (requestId, action) {
    setModalConfirmOpen(true);
    const activeRequest = pendingRequests.find(request => request.id == requestId);
    setModalConfirmMessage(`You are about to ${action} request from ${activeRequest.receiverName}`);
    setModalConfirmData({requestId: requestId, action: action});    
  };

  function handleDecline() {
    setModalConfirmOpen(false);
    setModalConfirmData({requestId: 0, action: null}); 
  }

  function handleAccept() {
    setModalConfirmOpen(false);
    try {
      updateRequest(modalConfirmData.requestId, modalConfirmData.action);
    } catch (error) {
      console.error('Error performing action:', error);
    }
  }

  return (
    <div className="container mt-5 p-3 bg-light" style={{ 'maxWidth': '900px' }}>
      <h3>Request List</h3>
      <div className="d-flex justify-content-center mb-4">
        <button
          className={`btn btn-primary mr-2`}
          onClick={() => setDisplayRequest(pendingRequests)}
        >
          Pending Requests
        </button>
        <button
          className={`btn btn-primary`}
          onClick={() => setDisplayRequest(solvedRequests)}
        >
          Solved Requests
        </button>
      </div>
          {displayRequests.length ? 
            displayRequests.map((request) => (
              <RenderRequest key={request.id} request={request} onAction={handleAction} /> ))
            : (<div className='row'> 
                  <h4>No requests at the moment...</h4> 
              </div> )
          }
          <ModalConfirm isOpen={modalConfirmOpen} 
                        message={modalConfirmMessage}
                        onConfirm={handleAccept}
                        onDecline={handleDecline}/>
    </div>
  );
};

export default RequestsList;