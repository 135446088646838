import React, { useState } from "react";
import MyItemsAxios from "../backendTools/MyItemsAxios";
import ModalCreateRequest from '../components/ModalRequest';
import { Button } from 'react-bootstrap';

import orgUsersList from "../TestData/orgUsersList";

function RequestTransitButton() {
    const [isOpen, setIsOpen] = useState(false);
    const [peopleList, setPeopleList] = useState([]);
    const [managerList, setManagerList] = useState([]);
  
    const fetchUserList = async () => {
        try {
            //const response = await MyItemsAxios.get('/organization/get-all-users');
            let peopleArray = [];
            let managerArray = [];
  
            // sort user list to two arrays based on role
            //response.data
            orgUsersList.forEach(user => {
                if (user.role === 'Manager' || user.role === 'Admin') {
                    managerArray.push(user);  
                } 
                peopleArray.push(user);
            });
            setPeopleList(peopleArray);
            setManagerList(managerArray);
        } catch (error) {
            console.log('Failed to fetch user list: ', error);
        }
    };
  
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
  
    // Callback function passed to ModalRequest
    const handleConfirm = (data) => { 
        console.log('Modal Confirmed with data:', data);
        handleClose(); // close the modal after action is done
    };
  
    return (
        <>
            <Button variant="primary" onClick={() => { fetchUserList(); handleOpen(); }}>Test request</Button> 
            {isOpen && <ModalCreateRequest 
                            isOpen={isOpen}
                            onConfirm={handleConfirm}  // Callback function when modal is confirmed
                            onDecline={handleClose}    // Callback function when modal is closed
                            recepientsList={peopleList} // List of people for select menu in the modal
                            managerList={managerList}   // List of managers for some action or display purpose
                        />} 
        </>
    );
};

export default RequestTransitButton;