import React, { useEffect, useState } from "react";
import axios from "axios";
import backendHost from "../backendTools/backendHost";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const minPasswordLength = 5;
const allowedUsernameChars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

const RegistrationUserComplete = ({onConfirm}) => {
    const query = new URLSearchParams(useLocation().search);
    const inviteCode = query.get('inviteCode');
    const [organization, setOrganization] = useState('DemoOrganization');
    const [isConfirmed, setConfirmed] = useState(false);
    const [isPasswordDoubled, setPasswordDoubled] = useState(true);
    const [isPasswordShort, setPasswordShort] = useState(false);
    const [isUsernameInvalid, setUsernameInvalid] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    function checkKey() {
        try {
            axios.post(backendHost + '/user/checkInvite', inviteCode, {
                headers: {
                  'Content-Type': 'text/plain'
                }
              })
            .then((checkResult)=>{
                if(checkResult.status === 200) {
                    setOrganization(checkResult.data);
                    setConfirmed(true);
                }
            });
        } catch {
            //error confirm
        }
    }

    useEffect(()=>{
        //send request with key
        checkKey();
    }, []);

    async function handleRegistration(event) {
        event.preventDefault();
        const mail = document.getElementById("email").value;
        const pass = document.getElementById("password1").value;
        const user = document.getElementById("username").value;
        //TODO process data before sending to backend
        const userMail = mail.trim();
        const userNick = user.trim();
        const registrationForm = {
            organization    : organization,
            username        : userNick,
            password        : pass,
            inviteCode      : inviteCode
        };
        await axios.post(backendHost + "/user/registration", registrationForm)
            .catch(function (error) {
                if (error.response) {
                    setAlertMessage(error.response.data);
                }
            })
            .then((registration)=> {
                if(registration.status === 200) {
                    onConfirm(registration.data);
                    navigate("/");
                }
            });
        
    }

    function validatePasses() {
        const pass1 = document.getElementById("password1").value;
        const pass2 = document.getElementById("password2").value;
        if(pass2) {
            if(pass1 === pass2) {
                setPasswordDoubled(true);
            } else {
                setPasswordDoubled(false);
            }
        }
        if(pass1.length < minPasswordLength) {
            setPasswordShort(true);
        } else {
            setPasswordShort(false);
        }
    }

    function checkUsernameSymbols(event) {
        const regex = new RegExp(`^[${allowedUsernameChars}]+$`);
        if(event.currentTarget.value !== '') {
            if(regex.test(event.currentTarget.value)) {
                setUsernameInvalid(false);
            } else {
                setUsernameInvalid(true);
            }
        } else {
            setUsernameInvalid(false);
        }
        }
        
    function backToMain() {
        navigate("/");
    }

    return (
        <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
            <div className="row p-1 p-lg-4 justify-content-center">
                <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
                    {isConfirmed ? (
                        <div>
                            {alertMessage && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        {alertMessage}
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" onClick={()=>{setAlertMessage(null);}} aria-label={t('action.close')}></button>
                                    </div>}
                            <form className="p-2" onSubmit={handleRegistration}> 
                                <div className="p-2">
                                    <label  title="Your organization name"
                                            htmlFor="organization"> {organization} </label>
                                </div>
                                <div className="p-2">
                                    <input  type="text" 
                                            id="username"
                                            className="form-control"
                                            placeholder={t('label.username')}
                                            title={t('label.username')} 
                                            onChange={checkUsernameSymbols}/>
                                </div>
                                <div className="p-2">
                                    <input  type="password" 
                                            id="password1"
                                            className="form-control"
                                            placeholder={t('label.password')} 
                                            title={t('label.password')} 
                                            onBlur={validatePasses}
                                            required/>
                                    {isPasswordShort && <p style={{ color: 'red' }}>{t('password.is.too.short')}</p>}
                                </div>
                                <div className="p-2">
                                    <input  type="password" 
                                            id="password2"
                                            className="form-control"
                                            placeholder={t('repeat.password')} 
                                            title={t('repeat.password')} 
                                            onBlur={validatePasses}
                                            required/>
                                    {!isPasswordDoubled && <p style={{ color: 'red' }}>{t('enter.the.same.password')}</p>}
                                </div>
                                { isUsernameInvalid && <p style={{ color: 'red' }}>{t('use.just.letters')}</p>}
                                <div className="p-2">
                                    <button type="submit"  className="form-control btn btn-outline-success"> {t('action.confirm')} </button>
                                </div>
                            </form>
                        </div>
                    ):(
                        <div>
                        <p>Checking invite... If you see this message longer than 10 seconds current invite may be expired or invalid</p>
                        <button className="btn btn-outline-success" onClick={backToMain}>Go main page</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RegistrationUserComplete;