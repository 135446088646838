import React, { useEffect, useState } from "react";
import { getCategoryById } from "../../categoryTools/CategoryBranch";
import ItemCategoryBages from "../../categoryTools/ItemCategoryBages";

function ItemCategories ({categoriesOfItem, categoryList, onCategoryClick}) {
    const [categoriesMap, setCategoriesMap] = useState(null);

    useEffect( () => {
        function compareChilds(a, b) {
            if(a.id < b.id) return -1;
            if(a.id > b.id) return 1;
            if(a.child != null && b.child != null) {
                return compareChilds(a.child, b.child);
            } else {
                if(a.child != null) return 1;
                if(b.child != null) return -1;
            }
        }
        
        if(categoryList != null && categoriesOfItem != null) {
            var parsedCategories = [];
            categoriesOfItem.forEach(categoryId => {
                parsedCategories.push(getCategoryById(categoryList, categoryId));
            });
            parsedCategories.sort(compareChilds);
            setCategoriesMap(parsedCategories);
        }
        
    }, [categoriesOfItem, categoryList]);
    
    return (
        <>
            {categoriesMap != null && (  
                <ItemCategoryBages categoryList={categoriesMap} onClick={onCategoryClick} />
            )}   
        </>    
    );

}

export default ItemCategories;