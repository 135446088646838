let orgUsersList = [
    {   id: 0, 
        username: 'Vasya',
        email: 'anymail@mail.ma',
        role: 'Member',
        activated: 1
    }, 
    {   id: 1, 
        username: 'Petya',
        email: 'petya220@mail.ma',
        role: 'Admin',
        activated: 1
    }, 
    {   id: 2, 
        username: 'Lena',
        email: 'lena2003@mail.ma',
        role: 'Member',
        activated: 0
    },{   id: 3, 
        username: 'Sasha',
        email: 'sasha@example.com',
        role: 'Member',
        activated: 1
    },
    {   id: 4, 
        username: 'Masha',
        email: 'masha@example.com',
        role: 'Member',
        activated: 1
    },
    {   id: 5, 
        username: 'Alex',
        email: 'alex@example.com',
        role: 'Manager',
        activated: 1
    }, 
    {   id: 6, 
        username: 'Olga',
        email: 'olga@example.com',
        role: 'Member',
        activated: 0
    },
    {   id: 7, 
        username: 'Ivan',
        email: 'ivan@example.com',
        role: 'Member',
        activated: 1
    },
    {   id: 8, 
        username: 'Nina',
        email: 'nina@example.com',
        role: 'Member',
        activated: 1
    },
    {   id: 9, 
        username: 'Dmitry',
        email: 'dmitry@example.com',
        role: 'Member',
        activated: 0
    }
];

export default orgUsersList;