import React, { useEffect, useState } from "react";

const UserButtons = ({ userId, userActivated, userActivationControl, changeRole }) => {
    const [activeStatus, setActive] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        if (userActivated) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [userActivated]);

    function handleBlockButton() {
        userActivationControl(userId);
        setDropdownOpen(false);
    }

    const handleRoleChange = (role) => {
        changeRole(userId, role);
        setDropdownOpen(false);
    };

    return (
        <div className="dropdown">
            <button
                className="btn btn-secondary dropdown-toggle"    //{activeStatus ? "btn btn-warning dropdown-toggle" : "btn btn-success dropdown-toggle"}
                type="button"
                id={"dropdownMenuButton" + userId}
                aria-haspopup="true" aria-expanded="false"
                data-bs-toggle="dropdown"
            >
                Actions
            </button>
            <div className="dropdown-menu" aria-labelledby={"dropdownMenuButton" + userId}>
                <button
                    className="dropdown-item"
                    onClick={() => handleBlockButton()}
                >
                    {activeStatus ? "Deactivate" : "Activate"}
                </button>
                <button
                    className="dropdown-item"
                    onClick={() => handleRoleChange("Member")}
                >
                    Change Role to Member
                </button>
                <button
                    className="dropdown-item"
                    onClick={() => handleRoleChange("Manager")}
                >
                    Change Role to Manager
                </button>
                <button
                    className="dropdown-item"
                    onClick={() => handleRoleChange("Admin")}
                >
                    Change Role to Admin
                </button>
            </div>
        </div>
    );
};

export default UserButtons;