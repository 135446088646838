import React, { useState } from "react";
import axios from "axios";
import backendHost from "../backendTools/backendHost";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const LoginPage = ({onLogin}) => {
    const [alertMessage, setAlertMessage] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    async function checkLogin(event) {
        event.preventDefault();
        const username = document.getElementById("username").value;
        const pass = document.getElementById("password").value;
        //TODO send data to backend
        const userName = username.trim();
        const loginForm = {
            "username": userName,
            "password": pass
        };

        await axios.post(backendHost + "/user/login", loginForm)
        .then((authorization)=> {
            if(authorization.status === 200) {
                onLogin(authorization.data);
                navigate("/");
            }
        })
        .catch(function (error) {
            if (error.response) {
                setAlertMessage(error.response.data);
            }
        });
    }

    return (
            <div className="container py-3 py-md-5 py-lg-5" style={{'maxWidth': '700px'}}>
                <div className="row p-1 p-lg-4 justify-content-center">
                    <div className="col-12 col-md-8 col-lg-8 p-3 p-md-4 p-lg-4 bg-light rounded-4">
                    {alertMessage && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        {alertMessage}
                                        <button type="button" className="btn-close" 
                                                data-bs-dismiss="alert" onClick={()=>{setAlertMessage(null);}} 
                                                aria-label={t('action.close')}>
                                        </button>
                                    </div>}
                        <form className="p-2" onSubmit={checkLogin}> 
                            <div className="p-2">
                                <input  type="text" 
                                        id="username"
                                        className="form-control" 
                                        placeholder={t('label.username')}
                                        required/>
                            </div>
                            <div className="p-2">
                                <input  type="password" 
                                        id="password"
                                        className="form-control"
                                        placeholder={t('label.password')} 
                                        required/>
                            </div>
                            <div className="p-2">
                                <button type="submit"  className="form-control btn btn-outline-success"> {t('action.login')} </button>
                            </div>
                            <div className="p-2">
                                <a href="/registration" className="form-control btn btn-outline-secondary"> {t('action.registration')} </a>
                            </div>
                            <div className="d-flex justify-content-end p-2">
                                <a href="/restore-password" className="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"> {t('password.forgot')} </a>
                            </div>            
                        </form>
                    </div>
                </div>
            </div>
    );
}

export default LoginPage;