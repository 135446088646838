// requestService.js
import MyItemsAxios from "../backendTools/MyItemsAxios"; 
import { dummyRequests } from "../TestData/dummyRequests";

const API_URL = '/organization/requests';

export const getRequests = async () => {
  try {
    //const response = await MyItemsAxios.get(API_URL);
    //return response.data;
    return dummyRequests;
  } catch (error) {
    console.error('Error fetching requests:', error);
    throw error;
  }
};

export const updateRequest = async (requestId, action) => {
  try {
    const response = await MyItemsAxios.put(`${API_URL}/${requestId}`, { action });
    return response.data;
  } catch (error) {
    console.error('Error updating request:', error);
    throw error;
  }
};