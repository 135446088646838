import React, { useState } from "react";
import { Button, Modal, Form, FormControl }  from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ModalCreateRequest({ isOpen, onConfirm, onDecline, recepientsList, managerList }) {
    const [selectedManager, setSelectedManager] = useState('');
    const [selectedRecepient, setSelectedRecepient] = useState('me?');
    const [requestDescription, setRequestDescription] = useState('');
    const { t } = useTranslation();
  
    const handleClose = () => {
      if (onDecline) onDecline();
    };
  
    const handleConfirm = () => {
      if (selectedManager && selectedRecepient && requestDescription && onConfirm) {
        // You can use the selected person and description here
        console.log('Selected Manager:', selectedManager);
        console.log('Selected Recepient:', selectedRecepient);
        console.log('Request Description:', requestDescription);
        onConfirm({ selectedManager, selectedRecepient, requestDescription });
      } else if (!selectedManager || !selectedRecepient) {
        alert(t('error.select_person')); // show error message when no person is selected
      } else if (!requestDescription) {
        alert(t('error.enter_description')); // show error message when description field is empty
      }
    };
  
    return (
      <>
        <Modal show={isOpen} onHide={handleClose} contentClassName="rounded-4">
          <Modal.Header closeButton>
            <Modal.Title>{t('request.title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form.Label>{t('request.select_manager')}</Form.Label>
            <FormControl as="select" onChange={(e) => setSelectedManager(e.target.value)}>
              <option value=''>{t('request.select_person')}</option> 
              {managerList.map((person) => (
                <option key={person.id} value={person.username}>{person.username}</option>
              ))}
            </FormControl>
            <br/>
            <Form.Label>{t('request.select_recipient')}</Form.Label>
            <FormControl as="select" onChange={(e) => setSelectedRecepient(e.target.value)}>
              <option value=''>{t('request.select_person')}</option> 
              {recepientsList.map((person) => (
                <option key={person.id} value={person.username}>{person.username}</option>
              ))}
            </FormControl>
            <br/>
            <Form.Group controlId="description">
              <Form.Label>{t('request.description')}</Form.Label>
              <Form.Control type="text-area" placeholder={t('placeholder.description')} onChange={(e) => setRequestDescription(e.target.value)}/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={handleClose}>{t('action.cancel')}</Button>
            <Button variant="outline-primary" onClick={handleConfirm}>{t('action.confirm')}</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default ModalCreateRequest;
