import React, { useState } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import MyItemsAxios from '../backendTools/MyItemsAxios';

const ImportExport = () => {
  const [file, setFile] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // Handler for file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handler for date range inputs
  const handleDateChange = (e, which) => {
    if (which === 'start') setStartDate(e.target.value);
    else if (which === 'end') setEndDate(e.target.value);
  };

  // Handler for form submission
  const handleSubmitImport = async (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await MyItemsAxios.post('/organization/import', formData);
      alert("Import Successful!"); // or handle as per your requirements
    } else alert("Please select a file to import.")
  };

  const handleSubmitExport = async (e) => {
    e.preventDefault();
    if (startDate && endDate) {
      await MyItemsAxios.get(`/organization/export?start=${startDate}&end=${endDate}`);
      alert("Export Successful!"); // or handle as per your requirements
    } else alert("Please select a start and an end date for the export.") 
  };

  return (
    <div className="container  mt-5 py-5 px-4" style={{'maxWidth': '700px'}}>
        <Card style={{ width: '18rem' }} className="mx-3 my-5">
            <Card.Body>
                <Form onSubmit={handleSubmitImport}>
                    <Form.Group controlId="formFile">
                        <Form.Label>Upload File for Import</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <Button className="my-3" variant="primary" type="submit">Import Data</Button>
                </Form>
            </Card.Body>
        </Card>

        <Card style={{ width: '18rem' }} className="mx-3 my-5">
          <Card.Body>
              <Form onSubmit={handleSubmitExport}>
                  <Form.Group controlId="startDateInput">
                      <Form.Label>Start Date Range For Export</Form.Label>
                      <Form.Control 
                          type="date" 
                          value={startDate} 
                          onChange={(e) => handleDateChange(e, 'start')}/>
                  </Form.Group>
                  <Form.Group controlId="endDateInput" className="my-2">
                      <Form.Label>End Date Range For Export</Form.Label>
                      <Form.Control 
                          type="date" 
                          value={endDate} 
                          onChange={(e) => handleDateChange(e, 'end')}/>
                  </Form.Group>
                  <Button className="my-2" variant="primary" type="submit">Export Data</Button>
              </Form>
          </Card.Body>
        </Card>
    </div>
  );
};

export default ImportExport;